import * as React from "react";
import { PaintCodeSet } from "@Models";
import { navigate } from "gatsby"
import './BrandSelectorMobile.scss';
import SwipeableViews from 'react-swipeable-views';
import { baseClassAnd, ClassableProps, childClassClosure } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { PaintMatchingContext } from "@Contexts";

type Props = ClassableProps;

const baseClass = 'brand-selector-mobile';
const childClass = childClassClosure(baseClass);

const slideScaleMin = 0.8;

type SlideProps = {
    codeSet: PaintCodeSet,
    index: number,
    slidePosition: number
}

const BrandSelectorMobileSlide: React.FunctionComponent<SlideProps> = (props: SlideProps) => {
    const pad = props.index > (props.slidePosition - 1) && props.index < (props.slidePosition + 1)
        ? Math.max(slideScaleMin, 1 - Math.abs(props.slidePosition - props.index))
        : slideScaleMin;
    const tileStyle = { transform: `scale(${pad})` };

    const onCtaClick = () => {
        navigate(props.codeSet.route);
    }


    return <div className={childClass('brand-slide')} key={props.codeSet.slug}>
        <div className={childClass('brand-slide-tile')} style={tileStyle} onClick={onCtaClick}>
            <img alt={props.codeSet.brandName} src={`/assets/fixed/logos/${props.codeSet.slug}.png`} className={childClass('brand-logo')} />
        </div>
    </div>
}

const BrandSelectorMobile: React.FunctionComponent<Props> = (props: Props) => {
    const [index, setIndex] = React.useState(0);
    const [slidePosition, setSlidePosition] = React.useState(0);
    const { allCodeSetNames } = React.useContext(PaintMatchingContext);
    const allCodeSets = Array.from(allCodeSetNames.map(PaintCodeSet.parse));

    const onChangeIndex = (index: number) => {
        setIndex(index);
    };

    const onSwitching = (index: number) => {
        setSlidePosition(index);
    }

    const onCtaClick = () => {
        navigate(allCodeSets[index].route);
    }

    return <div className={baseClassAnd(baseClass, props)}>
        <SwipeableViews
            className={childClass('swipe-container')}
            slideClassName={childClass('slide-container')}
            index={index}
            springConfig={{duration: '0.5s', delay: '0s'}}
            threshold={3}
            onChangeIndex={onChangeIndex}
            onSwitching={onSwitching}>
            {allCodeSets.map((codeSet, i) => <BrandSelectorMobileSlide key={i} codeSet={codeSet} index={i} slidePosition={slidePosition} />)}
        </SwipeableViews>
        <button type='button' className={childClass('cta')} onClick={() => onCtaClick()}>Select</button>
    </div>
}

export default BrandSelectorMobile;