import React from "react"
import "./_CommonShared.scss";
import './SourceSelectionPageLayout.scss';
import { graphql, Link } from "gatsby"
import SiteFooter, { FixMode } from "components/headersfooters/SiteFooter";
import { childClassClosure } from '@jcharlesworthuk/your-mum-core/dist/functions';
import BrandSelectorDesktop from "components/paint-converter/BrandSelectorDesktop";
import ConversionContainer from "components/paint-converter/ConversionContainer";
import PaintConverterHeader from "components/headersfooters/PaintConverterHeader";
import BrandSelectorMobile from "components/paint-converter/BrandSelectorMobile";
import PageMeta from "components/texty/PageMeta";

const baseClass = 'paint-conversion-chart-view';
const childClass = childClassClosure(baseClass);

type Props = {
    pageContext: {
    };
}

/**
 * This is the page where they select which brand to convert from.  It links off to the actual converter which is on another route.
 * Navigating to this page will load the data.
 */
const SourceSelectionPageLayout: React.FunctionComponent<Props> = (props: Props) => {
    return <div className={baseClass}>
        <PageMeta
            route='/paint-conversion-chart/'>
        </PageMeta>
        <PaintConverterHeader
            className={childClass('header')}
            title='Model Paint Conversion Charts'
            backArrowRoute='/' />

        <div className={childClass('container')}>
            <h2 className={childClass('subheading')}>Convert from...</h2>
            <BrandSelectorDesktop />
            <BrandSelectorMobile />
        </div>
        <SiteFooter fixed={FixMode.FixAlways} />
    </div>
}

export default SourceSelectionPageLayout;



export const pageQuery = graphql`
  query SourceSelectionPageLayoutQuery {
    paintListFileNames: allFile(filter: { sourceInstanceName: { eq: "paintLists" } } ) {
    edges {
        node {
            name
            }
        }
    }
   }
  `


