import * as React from "react";
import { PaintCodeSet } from "@Models";
import './BrandSelectorDesktop.scss';
import { ClassableProps, baseClassAnd, childClassClosure } from '@jcharlesworthuk/your-mum-core/dist/functions';
import LinkWrapper from "components/texty/LinkWrapper";
import { PaintMatchingContext } from "@Contexts";

type Props = ClassableProps;

const baseClass = 'brand-selector-desktop';
const childClass = childClassClosure(baseClass);

const BrandSelectorDesktop: React.FunctionComponent<Props> = (props: Props) => {
    const { allCodeSetNames } = React.useContext(PaintMatchingContext);

    return <div className={baseClassAnd(baseClass, props)}>
    <div className={childClass('tile-container')}>
    {allCodeSetNames.map(PaintCodeSet.parse).map(codeSet => <LinkWrapper className={childClass('brand-tile')} key={codeSet.slug} to={codeSet.route}>
            <div className={childClass('brand-tile-inner')}>
                <img alt={codeSet.brandName} src={`/assets/fixed/logos/${codeSet.slug}.png`} className={childClass('brand-logo')} />
            </div>
        </LinkWrapper>)}
    </div>
</div>
}

export default BrandSelectorDesktop;