import './UserBasketPopup.scss';
import * as React from "react";
import { PaintDropSvg } from 'components/svg';
import * as ReactGA from 'react-ga';
import { GaEventCategory, Paint } from "@Models";
import { childClassClosure, groupBy } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { UserBasketActionType, UserBasketContext } from '@Contexts';
import { removeFromStoredBasket } from '@Functions';

type Props = { onNoMoreLeft: () => void };
const baseClass = 'user-basket-popup';
const childClass = childClassClosure(baseClass);

const UserBasketPopup: React.FunctionComponent<Props> = (props: Props) => {
    const { myPaintList, dispatch: userBasketDispatch } = React.useContext(UserBasketContext);

    const onClickRemove = (item: Paint) => {
        ReactGA.event({ category: GaEventCategory.Basket, action: 'Remove Paint (from basket)', label: item.getUniquePaintCode() });
        removeFromStoredBasket(item);
        userBasketDispatch({ type: UserBasketActionType.RemovePaintFromMyList, item });
        if (myPaintList.length <= 1) {
            props.onNoMoreLeft();
        }
    }

    const groupedList = groupBy<Paint>(myPaintList, x => x.codeSet.slug);
    return <div className={baseClass}>
        <h2 className={childClass('popup-title')}>Saved Paints</h2>
        {groupedList.map(group =>
            <section key={group.key} className={childClass('brand-group')}>
                <h4 className={childClass('brand-group-title')}><img src={`/assets/fixed/logos/${group.key}.png`} className={childClass('brand-group-logo')} /></h4>
                <ul>
                    {group.value.map((item, index) => <li key={index} className={childClass('item')}>
                        <PaintDropSvg colour={item.colour.hex} />
                        <h3 className={childClass('item-code')}>{item.productCode}</h3>
                        <h4 className={childClass('item-description')}>{item.description}</h4>
                        <button className={childClass('item-remove')} onClick={() => onClickRemove(item)}>x</button>
                    </li>)}
                </ul>
            </section>
        )}
    </div>
}

export default UserBasketPopup;