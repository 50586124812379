import './PaintConverterHeader.scss';
import * as React from "react";
import { ListIconSvg, ArrowSvg } from 'components/svg';
import UserBasketPopup from 'components/user-basket/UserBasketPopup';
import * as ReactGA from 'react-ga';
import { GaEventCategory } from "@Models";
import { ClassableProps, baseClassAnd, childClassClosure } from '@jcharlesworthuk/your-mum-core/dist/functions';
import LinkWrapper from "components/texty/LinkWrapper";
import { UserBasketContext } from "@Contexts";


type Props = ClassableProps & {
    title: string;
    backArrowRoute?: string;
    backArrowRouteMobile?: string;
}


const baseClass = 'paint-converter-header'

const childClass = childClassClosure(baseClass);


const PaintConverterHeader: React.FunctionComponent<Props> = (props: Props) => {
    const [showBasketPopup, setShowBasketPopup] = React.useState(false);
    const { myPaintList } = React.useContext(UserBasketContext);

    const clickBasket = () => {
        ReactGA.event({ category: GaEventCategory.Basket, action: 'Toggle Basket Popup', label: showBasketPopup ? 'Hide' : 'Show' });
        setShowBasketPopup(!showBasketPopup);
    }

    const onScrimClick = () => {
        setShowBasketPopup(false);
    }

    return <header className={baseClassAnd(baseClass, props)}>
        {props.backArrowRoute &&
            <LinkWrapper to={props.backArrowRoute} className={childClass('back-button', 'desktop')} replace={true}><ArrowSvg /></LinkWrapper>}
        {props.backArrowRouteMobile &&
            <LinkWrapper to={props.backArrowRouteMobile} className={childClass('back-button', 'mobile')} replace={true}><ArrowSvg /></LinkWrapper>}            
        <h1 className={childClass('title')}>{props.title}</h1>
        {myPaintList.length > 0 &&
            <button className={childClass('list-button')} disabled={myPaintList.length == 0} onClick={clickBasket}>
                <ListIconSvg />
                {myPaintList.length > 0 && <span className={childClass('list-button-indicator')}>{myPaintList.length}</span>}
            </button>
        }
        <LinkWrapper to='/'><img className={childClass('logo')} alt='ModelShade Paint Conversion Charts' src="/assets/fixed/modelshade.svg" /></LinkWrapper>
        {showBasketPopup && <UserBasketPopup onNoMoreLeft={() => setShowBasketPopup(false)} />}
        {showBasketPopup && <div className={childClass('scrim')} onClick={onScrimClick} />}
    </header>

}

export default PaintConverterHeader;